<template>
  <div class="wrap">
    <b-modal
      v-model="deleteManagerModalShow"
      class="modal delete-reddit-modal"
      id="delete-reddit-modal"
      hide-header
      hide-header-close
      hide-footer
      hide-backdrop
    >
      <div class="modal__backdrop" @click="deleteManagerModalShow = !deleteManagerModalShow"></div>
      <div class="modal__wrap content-centered">
        <form class="modal__content">
          <div class="modal__close" @click="deleteManagerModalShow = !deleteManagerModalShow">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L21 21M21 1L1 21" stroke="#747185" stroke-width="1.5" stroke-linecap="round" />
            </svg>
          </div>
          <h4 class="modal__title">Удалить менеджера?</h4>
          <p class="delete-reddit-modal__text">
            Вы действительно хотите удалить <span>{{ getDeleteManagerName }}</span> из списка менеджеров?
          </p>
          <div class="modal__buttons">
            <button
              type="button"
              class="button button--cancel"
              @click="deleteManagerModalShow = !deleteManagerModalShow"
            >
              Отменить
            </button>
            <button @click="deleteManager" class="button login__submit" type="button">Удалить</button>
          </div>
        </form>
      </div>
    </b-modal>
    <sidebar></sidebar>
    <main class="main-content main-content--user">
      <div class="user__header">
        <div class="user__header-left-side">
          <router-link to="/" class="user__back">
            <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 17L1 9L9 1" stroke="#747185" stroke-linecap="round" />
            </svg>
            <span>Назад</span>
          </router-link>
          <h2 class="user__title">Мой аккаунт</h2>
        </div>
        <div class="user__header-right-side">
          <button @click="downloadBackup" class="clickable-item user__download">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M20.2999 14C20.0825 13.7997 19.7955 13.6921 19.4999 13.7V13.7C19.209 13.7026 18.9307 13.8193 18.725 14.0251C18.5193 14.2308 18.4025 14.5091 18.3999 14.8V18.6H2.19993V14.8C2.19732 14.5091 2.08059 14.2308 1.87486 14.0251C1.66914 13.8193 1.39086 13.7026 1.09993 13.7C0.808999 13.7026 0.530724 13.8193 0.324997 14.0251C0.119269 14.2308 0.00253905 14.5091 -7.03448e-05 14.8V19.7C-0.00808905 19.9956 0.0995455 20.2826 0.29993 20.5C0.517341 20.7004 0.804367 20.808 1.09993 20.8H19.5999C19.8909 20.7974 20.1691 20.6807 20.3749 20.4749C20.5806 20.2692 20.6973 19.9909 20.6999 19.7V14.8C20.6507 14.499 20.5112 14.22 20.2999 14V14Z"
                fill="#DFDFDF"
              />
              <path
                d="M9.79995 15.2L9.99995 15.4C10.1843 15.4691 10.3839 15.487 10.5775 15.4518C10.7712 15.4166 10.9517 15.3296 11.0999 15.2L15.9999 10C16.1906 9.79943 16.2954 9.53229 16.2919 9.25557C16.2884 8.97885 16.1769 8.71444 15.9812 8.51876C15.7855 8.32307 15.5211 8.21158 15.2444 8.20808C14.9677 8.20458 14.7005 8.30934 14.4999 8.50001L11.4999 11.7V1.30001C11.4973 1.00908 11.3806 0.730805 11.1749 0.525078C10.9692 0.31935 10.6909 0.20262 10.3999 0.200011C10.2484 0.191254 10.0967 0.212977 9.9537 0.263895C9.81072 0.314813 9.6794 0.39389 9.56752 0.49645C9.45563 0.59901 9.36545 0.722968 9.30232 0.860991C9.23918 0.999014 9.20437 1.1483 9.19995 1.30001V11.6L6.29995 8.40001C6.06671 8.20828 5.79327 8.07157 5.49995 8.00001C5.23793 8.0117 4.98911 8.11833 4.79995 8.30001C4.68626 8.39716 4.59326 8.51616 4.52646 8.64995C4.45966 8.78374 4.42044 8.92959 4.41111 9.07883C4.40178 9.22808 4.42254 9.37768 4.47216 9.51874C4.52178 9.65981 4.59924 9.78947 4.69995 9.90001L9.79995 15.2Z"
                fill="#DFDFDF"
              />
            </svg>
            <span>Выгрузить последний дамп</span>
          </button>
          <button @click="logout" type="button" class="clickable-item user__logout">
            <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.7407 26H25C26.1046 26 27 25.1046 27 24V3C27 1.89543 26.1046 1 25 1H12.7407M2 13.5L7.55556 7.30952M2 13.5L7.55556 19.6905M2 13.5H18.6667"
                stroke="#DFDFDF"
                stroke-width="2"
              />
            </svg>
          </button>
        </div>
      </div>
      <section class="content main-content__content">
        <div class="user">
          <div class="user__block">
            <div class="user__block-title">Обновить личную информацию</div>
            <div class="user__block-content user__block-content--personal-info">
              <label class="label-wrap">
                <span class="label">Имя</span>
                <input v-model="userForm.name" type="text" class="input input--w100" placeholder="" />
                <FormErrorListPrinter :errorList="userFormErrors.name" />
              </label>
              <label class="label-wrap">
                <span class="label">Почта</span>
                <input v-model="userForm.email" type="email" class="input input--w100" placeholder="" />
                <FormErrorListPrinter :errorList="userFormErrors.email" />
              </label>
            </div>
            <div class="user__block-buttons">
              <button @click="updateAccount" class="button" type="submit">Сохранить</button>
              <button @click="cancelUpdateAccount" type="button" class="button button--cancel">Отменить</button>
            </div>
          </div>
          <div class="user__block">
            <div class="user__block-title">Изменить пароль</div>
            <div class="user__block-content user__block-content--change-password">
              <label class="label-wrap">
                <span class="label">Старый пароль</span>
                <span class="input-wrap">
                  <input
                    v-model="userForm.old_password"
                    type="password"
                    class="input input--w100"
                    placeholder="********************"
                  />
                  <span class="show-password" @click="showPassword">
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10 6C10 7.21562 9.10457 8.20107 8 8.20107C6.89543 8.20107 6 7.21562 6 6C6 4.78438 6.89543 3.79893 8 3.79893C9.10457 3.79893 10 4.78438 10 6Z"
                        fill="white"
                        fill-opacity="0.25"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.97333 1.82657C1.82927 2.74886 0.830817 4.01571 0.0843772 5.61686C0.0288875 5.73583 0 5.867 0 6C0 6.133 0.0288875 6.26417 0.0843772 6.38314C1.81087 10.0834 4.87663 12 8 12C9.24407 12 10.4801 11.6957 11.6242 11.0957C12.8449 10.3294 13.4544 9.84242 14.3755 8.83886C14.9877 8.09661 15.5056 7.27088 15.9156 6.38314C15.9711 6.26417 16 6.133 16 6C16 5.867 15.9711 5.73583 15.9156 5.61686C14.1891 1.91657 11.1226 0 8 0C7.42969 0.000106036 6.86096 0.0641696 6.30311 0.191143C4.87186 0.541445 4.1409 0.89371 2.97333 1.82657ZM8 10.2857C8.81124 10.2857 9.62729 10.1237 10.4113 9.79543C11.731 9.12218 12.388 8.67318 13.2419 7.62514C13.6283 7.14857 13.9827 6.60771 14.2963 6C12.8019 3.10286 10.3761 1.71429 8 1.71429C7.9104 1.71429 7.82079 1.716 7.73119 1.72029C6.1827 1.84512 5.39548 2.1583 4.11819 3.054C3.19014 3.75429 2.3573 4.73229 1.70366 6C3.19814 8.89714 5.62467 10.2857 8 10.2857Z"
                        fill="white"
                        fill-opacity="0.25"
                      />
                    </svg>
                  </span>
                </span>
                <FormErrorListPrinter :errorList="userFormErrors.old_password" />
              </label>
              <label class="label-wrap">
                <span class="label">Новый пароль</span>
                <span class="input-wrap">
                  <input
                    v-model="userForm.password"
                    type="password"
                    class="input input--w100"
                    placeholder="********************"
                  />
                  <span class="show-password" @click="showPassword">
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10 6C10 7.21562 9.10457 8.20107 8 8.20107C6.89543 8.20107 6 7.21562 6 6C6 4.78438 6.89543 3.79893 8 3.79893C9.10457 3.79893 10 4.78438 10 6Z"
                        fill="white"
                        fill-opacity="0.25"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.97333 1.82657C1.82927 2.74886 0.830817 4.01571 0.0843772 5.61686C0.0288875 5.73583 0 5.867 0 6C0 6.133 0.0288875 6.26417 0.0843772 6.38314C1.81087 10.0834 4.87663 12 8 12C9.24407 12 10.4801 11.6957 11.6242 11.0957C12.8449 10.3294 13.4544 9.84242 14.3755 8.83886C14.9877 8.09661 15.5056 7.27088 15.9156 6.38314C15.9711 6.26417 16 6.133 16 6C16 5.867 15.9711 5.73583 15.9156 5.61686C14.1891 1.91657 11.1226 0 8 0C7.42969 0.000106036 6.86096 0.0641696 6.30311 0.191143C4.87186 0.541445 4.1409 0.89371 2.97333 1.82657ZM8 10.2857C8.81124 10.2857 9.62729 10.1237 10.4113 9.79543C11.731 9.12218 12.388 8.67318 13.2419 7.62514C13.6283 7.14857 13.9827 6.60771 14.2963 6C12.8019 3.10286 10.3761 1.71429 8 1.71429C7.9104 1.71429 7.82079 1.716 7.73119 1.72029C6.1827 1.84512 5.39548 2.1583 4.11819 3.054C3.19014 3.75429 2.3573 4.73229 1.70366 6C3.19814 8.89714 5.62467 10.2857 8 10.2857Z"
                        fill="white"
                        fill-opacity="0.25"
                      />
                    </svg>
                  </span>
                </span>
                <FormErrorListPrinter :errorList="userFormErrors.password" />
              </label>
              <label class="label-wrap">
                <span class="label">Повтор пароля</span>
                <span class="input-wrap">
                  <input
                    v-model="userForm.password_confirmation"
                    type="password"
                    class="input input--w100"
                    placeholder="********************"
                  />
                  <span class="show-password" @click="showPassword">
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10 6C10 7.21562 9.10457 8.20107 8 8.20107C6.89543 8.20107 6 7.21562 6 6C6 4.78438 6.89543 3.79893 8 3.79893C9.10457 3.79893 10 4.78438 10 6Z"
                        fill="white"
                        fill-opacity="0.25"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.97333 1.82657C1.82927 2.74886 0.830817 4.01571 0.0843772 5.61686C0.0288875 5.73583 0 5.867 0 6C0 6.133 0.0288875 6.26417 0.0843772 6.38314C1.81087 10.0834 4.87663 12 8 12C9.24407 12 10.4801 11.6957 11.6242 11.0957C12.8449 10.3294 13.4544 9.84242 14.3755 8.83886C14.9877 8.09661 15.5056 7.27088 15.9156 6.38314C15.9711 6.26417 16 6.133 16 6C16 5.867 15.9711 5.73583 15.9156 5.61686C14.1891 1.91657 11.1226 0 8 0C7.42969 0.000106036 6.86096 0.0641696 6.30311 0.191143C4.87186 0.541445 4.1409 0.89371 2.97333 1.82657ZM8 10.2857C8.81124 10.2857 9.62729 10.1237 10.4113 9.79543C11.731 9.12218 12.388 8.67318 13.2419 7.62514C13.6283 7.14857 13.9827 6.60771 14.2963 6C12.8019 3.10286 10.3761 1.71429 8 1.71429C7.9104 1.71429 7.82079 1.716 7.73119 1.72029C6.1827 1.84512 5.39548 2.1583 4.11819 3.054C3.19014 3.75429 2.3573 4.73229 1.70366 6C3.19814 8.89714 5.62467 10.2857 8 10.2857Z"
                        fill="white"
                        fill-opacity="0.25"
                      />
                    </svg>
                  </span>
                </span>
                <FormErrorListPrinter :errorList="userFormErrors.password_confirmation" />
              </label>
            </div>
            <div class="user__block-buttons">
              <button @click="updatePassword" class="button" type="submit">Изменить пароль</button>
              <button @click="cancelUpdatePassword" class="button button--cancel">Отменить</button>
            </div>
          </div>
          <div class="user__managers-wrap" v-if="getUser.is_admin">
            <div class="user__block">
              <div class="user__block-title">Менеджеры</div>
              <div class="managers user__block-content user__block-content--managers">
                <ul class="managers__list">
                  <li class="managers__item" v-for="manager in managers" :key="manager.id">
                    <span>{{ manager.name }}</span>
                    <span class="managers__divider"></span>
                    <span
                      ><a href="mailto:nooutway@gmail.com">{{ manager.email }}</a></span
                    >
                    <svg
                      class="managers__item-delete"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      data-modal="#delete-reddit-modal"
                      @click.prevent.stop="showDeleteModal(manager.id)"
                    >
                      <path d="M1 1L11 11M11 1L1 11" stroke="#747185" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                  </li>
                </ul>
              </div>
            </div>
            <div class="user__block" v-if="getUser.is_admin">
              <div class="user__block-title">Добавить менеджера</div>
              <div class="user__block-content">
                <label class="label-wrap user__label-wrap">
                  <span class="label">Имя</span>
                  <input v-model="addManagerForm.name" type="text" class="input input--w100" placeholder="Имя" />
                  <FormErrorListPrinter :errorList="addManagerFormErrors.name" />
                </label>
                <label class="label-wrap user__label-wrap">
                  <span class="label">Почта</span>
                  <input
                    v-model="addManagerForm.email"
                    type="email"
                    class="input input--w100"
                    placeholder="mail@gmail.com"
                  />
                  <FormErrorListPrinter :errorList="addManagerFormErrors.email" />
                </label>
              </div>
              <div class="user__block-buttons">
                <button @click="addManager" class="button" type="submit">Добавить</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import { mapActions, mapGetters } from "vuex";
import FormErrorListPrinter from "../components/FormErrorListPrinter";

const userFormDefault = {
  email: null,
  name: null,
  password: null,
  password_confirmation: null,
  old_password: null,
};

export default {
  name: "Home",
  metaInfo: {
    title: "BIG BOSS REDDIT",
    meta: [
      { charset: "utf-8" },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, maximum-scale=1",
      },
    ],
    link: [{ rel: "favicon", href: "public/favicon.ico" }],
  },
  computed: {
    ...mapGetters("RedditStore", ["getUser"]),
    getDeleteManagerName() {
      let manager = this.managers.find((i) => i.id == this.deleteManagerId);
      return manager ? manager.name : null;
    },
  },
  data() {
    return {
      sidebarShow: false,
      subreddits: [],
      userForm: { ...userFormDefault },
      userFormErrors: { ...userFormDefault },
      addManagerForm: { ...userFormDefault },
      addManagerFormErrors: { ...userFormDefault },
      deleteManagerModalShow: false,
      managers: [],
      deleteManagerId: null,
    };
  },
  components: {
    Sidebar,
    FormErrorListPrinter,
  },
  async mounted() {
    let userProfile = this.getUser;

    this.userForm.email = userProfile.email;
    this.userForm.name = userProfile.name;

    let { data: managers } = await this.getManagerListAction();
    this.managers = managers;
  },
  methods: {
    ...mapActions("RedditStore", {
      downloadBackupAction: "DOWNLOAD_BACKUP",
      inviteManagerAction: "INVITE_MANAGER",
      deleteManagerAction: "DELETE_MANAGER",
      getManagerListAction: "GET_MANAGER_LIST",
      updateProfileAction: "UPDATE_PROFILE",
      updatePasswordAction: "UPDATE_PASSWORD",
      me: "ME",
      logoutAction: "LOGOUT",
    }),
    async downloadBackup() {
      await this.downloadBackupAction();
    },
    async logout() {
      await this.logoutAction();
      this.$toast.success("Выход выполнен успешно");
      await this.$router.push({ name: "login" });
    },
    async updateAccount() {
      let loader = this.$loading.show();
      this.userFormErrors = {};
      try {
        await this.updateProfileAction(this.userForm);
        this.$toast.success("Успех");
        await this.me();
      } catch (e) {
        this.userFormErrors = e.response.data.errors;
        this.$toast.error("Ошибка обновления профиля");
      } finally {
        loader.hide();
      }
    },
    cancelUpdateAccount() {
      this.userForm.email = this.getUser.email;
      this.userForm.name = this.getUser.name;
    },
    async updatePassword() {
      let loader = this.$loading.show();
      this.userFormErrors = {};
      try {
        await this.updatePasswordAction(this.userForm);
        this.$toast.success("Успех");
        await this.me();
      } catch (e) {
        this.userFormErrors = e.response.data.errors;
        this.$toast.error("Ошибка смены пароля");
      } finally {
        loader.hide();
      }
    },
    cancelUpdatePassword() {
      this.userForm.password = null;
      this.userForm.password_confirmation = null;
      this.userForm.old_password = null;
    },
    async deleteManager() {
      await this.deleteManagerAction(this.deleteManagerId);
      this.$toast.success("Успех");
      this.deleteManagerModalShow = false;
      let { data: managers } = await this.getManagerListAction();
      this.managers = managers;
    },
    showDeleteModal(managerId) {
      this.deleteManagerId = managerId;
      this.deleteManagerModalShow = true;
    },
    async addManager() {
      let loader = this.$loading.show();
      this.addManagerFormErrors = {};
      try {
        await this.inviteManagerAction(this.addManagerForm);
        this.$toast.success("Успех");
        let { data: managers } = await this.getManagerListAction();
        this.managers = managers;
      } catch (e) {
        this.addManagerFormErrors = e.response.data.errors;
        this.$toast.error("Ошибка добавления менеджера");
      } finally {
        loader.hide();
      }
    },
    showPassword(e) {
      let input = e.target.closest(".input-wrap").querySelector("input");

      if (input.getAttribute("type") === "password") {
        input.setAttribute("type", "text");
      } else {
        input.setAttribute("type", "password");
      }
    },
  },
};
</script>

<style lang="scss">
body {
  background-image: url("../../public/img/main-background.png");
}

.user {
}

.user__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.user__download {
  margin-right: 15px;
  color: #dfdfdf;
}

.user__header-left-side {
  display: flex;
  align-items: center;
}

.user__header-right-side {
  display: flex;
  align-items: center;
}

.user__back {
  display: flex;
  align-items: center;
  margin-right: 20px;

  svg {
    margin-right: 15px;
  }

  &:hover {
    path {
      stroke: $primary;
    }
  }
}

.user__title {
  margin: 0;
  font-size: 28px;
}

.user__block {
  margin-bottom: 25px;
  background: rgba(0, 0, 0, 0.25);
  border: 1px solid #282828;
  border-radius: 10px;
}

.user__block-title,
.user__block-content,
.user__block-buttons {
  padding: 20px;
}

.user__label-wrap {
  margin-bottom: 0;
}

.user__block-title {
  border-bottom: 1px solid #282828;
}

.user__block-buttons {
  border-top: 1px solid #282828;
}

.user__block-title {
  font-weight: 500;
}

.user__block-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 20px;

  &--managers {
    display: block;
  }
}

.user__block-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 220px));
  grid-auto-rows: auto;
  grid-gap: 15px;
}

.user__managers-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-gap: 20px;
  width: 100%;
}
.managers {
}
.managers__list {
  width: 100%;
  padding-left: 0;
  margin: 0;
  list-style: none;
}
.managers__item {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  line-height: 1;
  margin-bottom: 20px;
}
.managers__divider {
  margin: 0 10px;
  flex-grow: 1;
  border-bottom: 1px dashed #474747;
}
.managers__item-delete {
  margin-left: 10px;
  cursor: pointer;
  margin-bottom: 2px;
}

.user__logout {
  display: flex;

  svg {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .user__header {
    flex-direction: column;
  }

  .user__header-right-side {
    margin-top: 15px;
  }
}

@media screen and (max-width: 576px) {
  .user__block-buttons {
    align-items: center;
    justify-content: center;
  }

  .user__managers-wrap {
    grid-template-columns: 1fr;
  }

  .user__logout {
    display: flex;

    svg {
      width: 18px;
    }
  }

  .user__title {
    font-size: 18px;
  }
}
</style>
